@import './src/Login/Resources/scss/color.scss';

form{
	.hidden{
		display:none;
	}
	
	.color-white{
		color:$color-white;
	}

	&.was-validated{ 
		.form-check-input:valid ~ .form-check-label{  
			color:$color-white;
		}
	}
}